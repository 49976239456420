import { createApp } from 'vue';
import { pinia, /*vuetify,*/ i18n } from './setup';
import { router } from './router/index';
import App from './views/App.vue';
import { useRootStore } from './store/store';
import { useAuthStore } from "./store/authentication";
import { useErrorsStore } from "./store/errors";
import { useSuccessStore } from "./store/success";
import { useVersioningStore } from "./store/versioning";
import { useNotificationStore } from "./store/notification";
import { useAppearanceStore } from "./store/appearance";
import { usePreferencesStore } from "./store/preferences";
import { useCompaniesStore } from "./store/companies";
import { Form, Field, defineRule, ErrorMessage } from 'vee-validate';
import { required, min, email,numeric } from '@vee-validate/rules';
import '@mdi/font/css/materialdesignicons.css';
//import { aliases, mdi } from 'vuetify/iconsets/mdi';

import PrimeVue from 'primevue/config';
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"
import "primeflex/primeflex.css"
//import "primevue/resources/themes/saga-blue/theme.css"
//import 'primevue/resources/themes/bootstrap4-dark-blue/theme.css';
//import "primevue/resources/themes/aura-light-pink/theme.css";
//import "primevue/resources/themes/aura-dark-green/theme.css";
//import "primevue/resources/themes/md-light-indigo/theme.css"
//import 'primevue/resources/themes/aura-light-green/theme.css'
//import "primevue/resources/themes/aura-dark-blue/theme.css";
import "primevue/resources/themes/aura-dark-amber/theme.css";
import ConfirmationService from 'primevue/confirmationservice';
import PVButton from 'primevue/button';
import PVPanel from 'primevue/panel';
import PVCard from 'primevue/card';
import PVInputText from 'primevue/inputtext';
import PVDataTable from 'primevue/datatable';
import PVColumn from 'primevue/column';
import PVTooltip from 'primevue/tooltip';
import PVToastService from 'primevue/toastservice';
import PVDialogService from 'primevue/dialogservice';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('numeric', numeric);

// Subscribe to store updates and attach to the window the unsubscribe functions
window.unsubscribeFunction = function() {
  const preferences = usePreferencesStore();
  const authentication = useAuthStore();
  const appearance = useAppearanceStore();

  preferences.subscribe((mutation, state) => {
    localStorage.setItem('store.preferences', JSON.stringify(state));
  });
  
  authentication.subscribe((mutation, state) => {
    localStorage.setItem('store.authentication', JSON.stringify(state));
  });
  
  appearance.subscribe((mutation, state) => {
    localStorage.setItem('store.appearance', JSON.stringify(state));
  });
  
};

// Handle common errors inbound
window.axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response === undefined) return Promise.reject(error);
  if (error.response.status == 401) {
    // Handle unauthorised by force logging out our user and then putting them to the login page
    const authentication = useAuthStore();
    authentication.checkAuthState();
  }

  if (error.response.status == 500) {
    const errors = useErrorsStore();
    errors.setErrors([
      'An error has occurred, if this persists please contact the system administrator.'
    ]);
  }

  // Check if we have generic errors and fill the error state and module
  if (error.response.data.errors) {
    const errors = useErrorsStore();
    errors.setErrors(error.response.data.errors);
  }

  return Promise.reject(error);
});

router.beforeEach((to, from, next) => {
  document.title = to.name;
  const versioning = useVersioningStore();
  versioning.checkVersion();
  next();
  if (versioning.updateAvailable) {
    versioning.updateSite();
  }
});


const app = createApp({
  components: {
    App,
  },
  mounted() {
    const rootStore = useRootStore();
    rootStore.boot();
  },
  template: "<App/>"
});


if (process.env.NODE_ENV === 'development') {
  app.config.performance = true;
}

app.config.compilerOptions.whitespace = 'condense';

app.config.errorHandler = (err, instance, info) => {

  // Handle the error globally
  console.error("Global error:", err);
  console.log("Vue instance:", instance);
  console.log("Error info:", info);

  // Add code for UI notifications, reporting or other error handling logic
};

app.use(pinia);
//app.use(i18n);
app.use(router);

app.use(PrimeVue, {
  unstyled:false,
});
app.use(ConfirmationService);
app.use(PVToastService);
app.use(PVDialogService);
app.component('PVButton', PVButton);
app.component('PVPanel', PVPanel);
app.component('PVCard', PVCard);
app.component('PVInputText', PVInputText);
app.component('PVColumn', PVColumn);
app.component('PVDataTable', PVDataTable);
app.directive('tooltip', PVTooltip);

app.component('VueDatePicker', VueDatePicker);

app.component('ValidationObserver', Form);
app.component('ValidationProvider', Field);
app.component('ErrorMessage', ErrorMessage);

app.mount("#app");
