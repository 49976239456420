<template>
  <div class="flex flex-row justify-content-between flex-wrap">
    <NavigationDrawer/>
    <div>
      <PVButton label="Log Out" xsize="small" icon="material-icons exit_to_app" variant="text" @click="authenticationStore.logout()"/>
    </div>
  </div>
</template>

<script>
import NavigationDrawer from "./navigation/NavigationDrawer.vue";
import { useAuthStore } from "../../../store/authentication";

export default {
  name: 'NavigationToolBar',
  components: { NavigationDrawer },
  setup() {
    const authenticationStore = useAuthStore();
    return { authenticationStore };
  },
}
</script>

<style scoped>

</style>
